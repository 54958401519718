import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../../components/Layout'
import { RingBaseSelect } from '../../components/RingBaseSelect'
import { SEO } from '../../components/SEO'
import { Txt } from '../../components/Txt'

export const Head = () => (
    <SEO
        title="CAD-platinumのセミオーダーは1万通りのリングに独自のアレンジも加えて世界に一つの結婚指輪をお求め頂けます"
        description="リングのベース画像を見ながらお好みの加工を加え、理想のリングにカスタマイズ。1万通りから1つのリングに辿り着いたら、独自アレンジでおふたりらしさをちょっぴり加えて特別なリングを製作しよう。"
        page="semiorder"
    />
)

const SemiorderPage: React.FC = () => {
    return (
        <Layout breadcrumbs={[{ name: 'セミオーダー' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <HeroImageWrapper>
                        <StaticImage
                            src="../../images/customize/semi-1.jpg"
                            alt="10,000通りを超えるカスタマイズ。スマホで簡単オーダーメイド"
                        />
                    </HeroImageWrapper>
                    <CatchWrapper>
                        <CatchTxt size="l">プラチナ価格</CatchTxt>
                        <CatchTxt size="l">19,800円~</CatchTxt>
                    </CatchWrapper>
                    <StaticImage
                        src="../../images/customize/semi-2.jpg"
                        alt=""
                    />
                    <RingBaseSelect marginTop="50px" />
                </Grid>
            </Grid>
        </Layout>
    )
}

const HeroImageWrapper = styled('h1')({
    margin: 0,
})
const CatchWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: '60px',
})
const CatchTxt = styled(Txt)({
    marginBottom: '8px',
})

export default SemiorderPage
